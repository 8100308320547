import React from 'react';
import {DotTypography, DotIllustration} from '@digital-ai/dot-components';
import Page from '../../components/Page/Page';


import {
    StyledNotFoundContainer,
    StyledNotFoundText
} from './NotFoundPage.styles';

const NotFoundPage = () => <>
    <Page displayTitle={false} showBreadcrumb={false}>
        <StyledNotFoundContainer data-testid='not-found-page__cointainer'>
            <DotIllustration illustrationId='digitalai-empty' data-testid='not-found-page__illustration'/>
            <StyledNotFoundText>
                <DotTypography variant='h1' data-testid='not-found-page__title'>Page Not Found</DotTypography>
                <DotTypography variant='body1' data-testid='not-found-page__message'>Sorry, but the page you were trying to view does not exist.</DotTypography>
            </StyledNotFoundText>
        </StyledNotFoundContainer>
    </Page>
</>;

export default NotFoundPage;
