import React from 'react';
import PropTypes from 'prop-types';
import {useOutletContext, useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {DotTypography, DotBreadcrumbs} from '@digital-ai/dot-components';
import {
    PageContainer, PageContentContainer, PageTitle, StyledActionToolbar, BreadcrumbAction
} from './Page.styles';
import {getBreadcrumb} from '../Navigation/Navigation.data';
import {getBannerMessageInfo} from '../../stores/UserMessagesSlice';
import NotificationBanner from '../NotificationBanner/NotificationBanner';

const Page = ({
    width = '80%',
    margin = '0 auto',
    displayTitle = true,
    customTitle = null,
    titleActions = null,
    breadcrumbActions = null,
    showBreadcrumb = true,
    customBreadcrumb = null,
    children
}) => {
    const location = useLocation();
    const outletContext = useOutletContext();
    const title = customTitle ?? outletContext?.title ?? null;
    const bannerMessageInfo = useSelector(getBannerMessageInfo);

    return <>
        {showBreadcrumb && <StyledActionToolbar>
            {customBreadcrumb || <DotBreadcrumbs items={getBreadcrumb(location)} maxItems={5} data-testid='page__breadcrumbs'/>}
            <BreadcrumbAction>
                {breadcrumbActions}
            </BreadcrumbAction>
        </StyledActionToolbar>}
        <NotificationBanner/>
        <PageContainer width={width} margin={margin} banner={bannerMessageInfo}>
            {
                displayTitle && <PageTitle data-testid='page__title'>
                    <DotTypography variant='h1' className='left' key='title'>{title}</DotTypography>
                    <div className='right'>{titleActions}</div>
                </PageTitle>
            }
            <PageContentContainer data-testid='page__content-container'>
                {children}
            </PageContentContainer>
        </PageContainer>
    </>;
};

Page.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.element
    ]),
    width: PropTypes.string,
    margin: PropTypes.string,
    displayTitle: PropTypes.bool,
    customTitle: PropTypes.string,
    customBreadcrumb: PropTypes.element,
    showBreadcrumb: PropTypes.bool,
    titleActions: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.element
    ]),
    breadcrumbActions: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.element
    ])
};

export default Page;
