import React, {Suspense, lazy} from 'react';
import {Route, Routes} from 'react-router-dom';

import MetaDataManager from './components/MetaDataManager';
import UploadManager from './components/UploadManager';
import Loader from './components/Loader';

import {ROUTES} from './constants/global';
import NotFoundPage from './pages/NotFoundPage/NotFoundPage';

const AllowedCountriesPage = lazy(() => import('./pages/AllowedCountriesPage'));
const App = lazy(() => import('./components/App'));
const CompaniesPage = lazy(() => import('./pages/CompaniesPage'));
const ContractManagementPage = lazy(() => import('./pages/ContractManagementPage'));
const AppAwareDashboardPage = lazy(() => import('./pages/AppAwareDashboardPage/AppAwareDashboardPage'));
const AppAwareAlerts = lazy(() => import('./pages/AppAwareAlertsPage/AppAwareAlertsPage'));
const AppAwareEvents = lazy(() => import('./pages/AppAwareEventsPage/AppAwareEventsPage'));
const HomePage = lazy(() => import('./pages/HomePage'));
const KitManagementPage = lazy(() => import('./pages/KitManagementPage'));
const LicenseManagementPage = lazy(() => import('./pages/LicenseManagementPage'));
const LoginPage = lazy(() => import('./pages/LoginPage'));
const SsoLoginReturnPage = lazy(() => import('./pages/SsoLoginReturnPage/SsoLoginReturnPage'));
const NotificationCreatePage = lazy(() => import('./pages/NotificationCreatePage'));
const NotificationsManagementPage = lazy(() => import('./pages/NotificationsManagementPage'));
const NotificationsPage = lazy(() => import('./pages/NotificationsPage'));
const OAuthPage = lazy(() => import('./pages/OAuthPage'));
const ProductPage = lazy(() => import('./pages/ProductPage'));
const ProfilePage = lazy(() => import('./pages/ProfilePage'));
const ProtectionHistoryPage = lazy(() => import('./pages/ProtectionHistoryPage/ProtectionHistoryPage'));
const ReleaseListPage = lazy(() => import('./pages/ReleaseListPage'));
const SetupPage = lazy(() => import('./pages/SetupPage'));
const FileSharePage = lazy(() => import('./pages/FileSharePage/FileSharePage'));
const UsersPage = lazy(() => import('./pages/UsersPage'));
const TokenPage = lazy(() => import('./pages/TokenPage/TokenPage'));
const AppAwareManagementPage = lazy(() => import('./pages/AppAwareManagementPage/AppAwareManagementPage'));
const MaliciousPackagesPage = lazy(() => import('./pages/MaliciousPackagesPage'));

const wrapElementInSuspense = (element) => (<Suspense fallback={<Loader/>}>{element}</Suspense>);

// routes that don't have url params need to be exact
export default (
    <MetaDataManager>
        <UploadManager>
            <Routes>
                <Route path={ROUTES.ssoLogin.route} element={wrapElementInSuspense(<SsoLoginReturnPage/>)} />
                <Route path={ROUTES.login.route} element={wrapElementInSuspense(<LoginPage/>)} />
                <Route path={ROUTES.setup.route} element={wrapElementInSuspense(<SetupPage/>)} />
                <Route element={wrapElementInSuspense(<App/>)}>
                    <Route path={ROUTES.home.route} element={wrapElementInSuspense(<HomePage/>)} />
                    <Route path={ROUTES.productDownload.route} element={wrapElementInSuspense(<ProductPage/>)} />
                    <Route path={ROUTES.manageLicenses.route} element={<LicenseManagementPage/>} />
                    <Route path={ROUTES.manageLicensesById.route} element={wrapElementInSuspense(<LicenseManagementPage matchPattern={ROUTES.manageLicensesById.route}/>)} />
                    <Route path={ROUTES.manageLicenseTokens.route} element={wrapElementInSuspense(<TokenPage/>)} />
                    <Route path={ROUTES.protectionHistory.route} element={wrapElementInSuspense(<ProtectionHistoryPage/>)} />
                    <Route path={ROUTES.keyAndData.route} element={wrapElementInSuspense(<KitManagementPage/>)} />
                    <Route path={ROUTES.releases.route} element={wrapElementInSuspense(<ReleaseListPage/>)} />

                    <Route path={ROUTES.appAwareDashboard.route} element={wrapElementInSuspense(<AppAwareDashboardPage/>)} />
                    <Route path={ROUTES.appAwareAlerts.route} element={wrapElementInSuspense(<AppAwareAlerts/>)} />
                    <Route path={ROUTES.appAwareEvents.route} element={wrapElementInSuspense(<AppAwareEvents/>)} />

                    <Route path={ROUTES.supportAppAwareSetup.route} element={wrapElementInSuspense(<AppAwareManagementPage/>)} />
                    <Route path={ROUTES.manageAppAwareSetup.route} element={wrapElementInSuspense(<AppAwareManagementPage/>)} />

                    <Route path={ROUTES.manageUsers.route} element={wrapElementInSuspense(<UsersPage/>)} />
                    <Route path={ROUTES.manageApiCredentials.route} element={wrapElementInSuspense(<OAuthPage/>)} />
                    <Route path={ROUTES.manageFileShare.route} element={wrapElementInSuspense(<FileSharePage/>)} />
                    <Route path={ROUTES.manageTeam.route} element={wrapElementInSuspense(<UsersPage/>)} />
                    <Route path={ROUTES.manageCompanies.route} element={wrapElementInSuspense(<CompaniesPage/>)} />
                    <Route path={ROUTES.manageContracts.route} element={wrapElementInSuspense(<ContractManagementPage/>)} />
                    <Route path={ROUTES.manageAllowedCountries.route} element={wrapElementInSuspense(<AllowedCountriesPage/>)} />
                    <Route path={ROUTES.manageMaliciousPackageExclusions.route} element={wrapElementInSuspense(<MaliciousPackagesPage/>)} />
                    <Route path={ROUTES.manageMaliciousPackageOverrides.route} element={wrapElementInSuspense(<MaliciousPackagesPage/>)} />
                    <Route path={ROUTES.manageMaliciousPackages.route} element={wrapElementInSuspense(<MaliciousPackagesPage/>)} />

                    <Route path={ROUTES.notifications.route} element={wrapElementInSuspense(<NotificationsPage/>)} />
                    <Route path={ROUTES.notificationById.route} element={wrapElementInSuspense(<NotificationsPage matchPattern={ROUTES.notificationById.route}/>)} />
                    <Route path={ROUTES.manageNotifications.route} element={wrapElementInSuspense(<NotificationsManagementPage/>)} />
                    <Route path={ROUTES.manageNotificationCreateById.route} element={wrapElementInSuspense(<NotificationCreatePage matchPattern={ROUTES.manageNotificationCreateById.route}/>)} />

                    <Route path={ROUTES.profile.route} element={wrapElementInSuspense(<ProfilePage/>)} />
                    <Route path='*' element={wrapElementInSuspense(<NotFoundPage/>)} />
                </Route>
            </Routes>
        </UploadManager>
    </MetaDataManager>
);
